<template>
  <v-footer class="copyright mobile">
    <p>Copyright © {{ currentYear }} - {{ $t("seo.university") }}</p>
    <p>Made by Dmytro Olkhovskyi with<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg></p>
  </v-footer>
</template>

<script>
export default {
  name: "FooterMobile",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
}
</script>

<style scoped>
  .copyright.mobile {
    background-color: rgba(33, 150, 243, .1);
  }

  .copyright {
    font-size: 0.8em;
    line-height: 16px;
  }

  .copyright svg{
    color: #e7515a;
    fill: rgba(231, 81, 90, 0.4196078431);
    height: 14px;
    position: relative;
    top: 3px;
  }

  .copyright p {
    margin: 3px 0;
  }
</style>
