import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false
    },
    breakpoint: {
        mobileBreakpoint: 1024 // This is equivalent to a value of 960
    },
});
