<template>
  <v-container>
  <v-row>
    <v-col cols="12" id="schedule-results">
        <v-skeleton-loader
            v-if="data.loading"
            class="mx-auto"
            width="100%"
            type="date-picker-days"
        ></v-skeleton-loader>

        <template v-if="!data.clear && ! data.loading">

          <v-tabs
              v-model="groupTab"
              v-if="data.filterData.groups && data.table.length > 1"
              show-arrows
          >
            <v-tab v-for="(groupData, groupIndex) in data.filterData.groups" :key="groupIndex" >{{ groupData.name }}</v-tab>
          </v-tabs>

          <template v-if="Object.keys(data.table[groupTab > data.table.length-1 ? 0 : groupTab]).length">
            <template v-for="(rowItem, rowIndex) in data.table[groupTab > data.table.length-1 ? 0 : groupTab]">

              <h4 class="text-center schedule-date-mobile" :key="rowIndex+'test' + groupTab">{{ [ rowItem.date, "DD.MM.YYYY" ] | moment('dddd') }} - {{ rowItem.date }}</h4>

              <div v-for="(scheduleItem, scheduleIndex) in rowItem.classes" :key="scheduleIndex + ':' + rowIndex" class="schedule-item-mobile">

                <v-divider v-if="scheduleIndex !== 0" class="schedule-item-mobile-divider"></v-divider>
                <p><strong>{{ scheduleItem.num }} {{ $t("schedule.class") }}</strong> <span class="schedule-item-small-text">({{ data.heading[scheduleItem.num].s }}-{{ data.heading[scheduleItem.num].e }})</span></p>

                <v-divider></v-divider>
                <div v-for="(classItem, classIndex) in scheduleItem.class.lessons" v-bind:key="groupTab+rowIndex+scheduleIndex+classIndex" class="schedule-lesson-block">

                  <h4>{{ classItem.lesson }} <span v-if="classItem.lessonType" class="schedule-lesson-type">[{{ classItem.lessonType }}]</span></h4>

                  <template v-if="scheduleItem.class.city">
                    <v-divider></v-divider>
                    <p>{{ scheduleItem.class.city }}</p>
                  </template>

                  <v-divider></v-divider>

                  <template v-if="scheduleItem.class.withSubGroups">
                    <p>
                      {{ classItem.subGroup.name }}
                      <span class="schedule-text-small">({{ classItem.subGroup.num }}&nbsp;{{ $t("schedule.subgroup") }})</span>
                    </p>
                  </template>
                  <template v-else>
                    <p v-for="(groupItem, groupIndex) in scheduleItem.class.groups" :key="groupTab+rowIndex+scheduleIndex+classIndex+groupIndex">
                      {{ groupItem.name }}
                      <span class="schedule-text-small" v-if="groupItem.form">({{ groupItem.form }})</span>
                    </p>
                  </template>
                  <v-divider></v-divider>

                  <p v-if="classItem.room !== 'дом_ПК'">{{ $t("schedule.room") }} {{ classItem.room }}</p>
                  <p v-else>{{ $t("schedule.remote") }}</p>

                  <p v-if="classItem.room2 && classItem.distance === '0'">
                    <v-divider></v-divider>
                    {{ $t("schedule.shelter") }}: {{ $t("schedule.room") }} {{ classItem.room2 }}</p>

                  <v-divider></v-divider>
                  <p>{{ classItem.teacher }}</p>

                </div>

              </div>
            </template>
          </template>
          <template v-else>
            <div v-bind:key="'group'+groupIndex">
              <h3 class="mt-3 mb-4">{{ $t('schedule.no_results') }}</h3>
              <p>{{ $t('schedule.try_again') }}</p>
            </div>
          </template>
        </template>

        <div class="mt-3" v-html="$t('main_info')" v-if="data.clear && ! data.loading"></div>
    </v-col>
  </v-row>
  </v-container>
</template>

<style scoped>
  .schedule-date-mobile {
    background-color: rgba(33, 150, 243, .3);
    line-height: 30px;
    margin: 10px 0;
  }

  .schedule-item-mobile hr {
    margin: 4px 0;
  }

  .schedule-lesson-block + .schedule-lesson-block {
    border-top: 3px solid rgba(153, 153, 153, 0.4);
    margin-top: 7px;
    padding-top: 7px;
  }

  .schedule-item-mobile p {
    margin: 3px 0;
  }

  .schedule-item-small-text {
    font-size: 0.75em;
  }

  .schedule-lesson-type {
    font-size: 0.85em;
    font-weight: normal;
  }

  .schedule-item-mobile-divider {
    background-color: rgba(33, 150, 243, .3);
    padding: 3px 0;
    border: 0;
  }
</style>

<script>
import { useScheduleStore } from "@/stores/schedule";

export default {
  name: 'WelcomeInfo',

  setup() {
    const data = useScheduleStore();

    return {
      data
    }
  },

  data () {
    return {
      groupTab: 0,
      classItem: {},
      groupIndex: 0
    }
  },
}
</script>
