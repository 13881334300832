import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import i18n from './i18n'
import VueMoment from 'vue-moment'
// import VueHtmlToPaper from 'vue-html-to-paper'

import { createPinia, PiniaVuePlugin } from 'pinia'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// const printOptions = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
//     "https://unpkg.com/kidlat-css/css/kidlat.css",
//   ],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: false, // if false, the window will not close after printing
//   windowTitle: window.document.title, // override the window title
// }
//
// Vue.use(VueHtmlToPaper, printOptions)

const moment = require('moment')
require('moment/locale/uk')

Vue.use(VueMoment, {
  moment
})

new Vue({
  vuetify,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')
