<template>
  <v-container>
    <v-row v-if="isPrintMode">
      <v-col cols="12" class="schedule-print-info">
        <p>Дати: з {{ data.filterData['dateStart'] }} по {{ data.filterData['dateEnd'] }}</p>
        <p v-if="data.filterData['prepName']">Викладач: {{ data.filterData['prepName'] }}</p>
        <p v-if="data.filterData['roomName']">Аудиторія: {{ data.filterData['roomName'] }}</p>
        <p v-if="data.filterData['groupsName']">Групи: {{ data.filterData['groupsName'] }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" id="schedule-results">
          <v-skeleton-loader
              v-if="data.loading"
              class="mx-auto"
              width="100%"
              type="table-thead, table-tbody"
              :types="{ 'table-tbody': 'table-row-divider@6', 'table-row' : 'table-cell@10', 'table-thead' : 'heading@10' }"
          ></v-skeleton-loader>
            <template v-if="!data.clear && !data.loading">

              <v-tabs v-model="groupTab" v-if="data.filterData.groups && data.table.length > 1">
                  <v-tab v-for="(groupData, groupIndex) in data.filterData.groups" :key="groupIndex" >{{ groupData.name }}</v-tab>
              </v-tabs>

                  <v-simple-table
                      v-if="Object.keys(data.table[groupTab > data.table.length-1 ? 0 : groupTab]).length"
                      fixed-header
                      class="schedule-table"
                      id="schedule_table"
                      :height="tableHeight"
                  >
                    <thead>
                      <tr>
                        <th v-for="(item, index) in data.heading" v-bind:key="index+'head'" scope="col">
                          {{ index }} {{ $t('schedule.class') }} <br> <span class="schedule-text-small-x">({{ item.s }}-{{ item.e }})</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(rowItem, rowIndex) in data.table[groupTab > data.table.length-1 ? 0 : groupTab]">
                        <tr v-bind:key="rowIndex+'1'" class="text-center schedule-date-row">
                          <td colspan="10">{{ [ rowItem.date, "DD.MM.YYYY" ] | moment('dddd') }} - {{ rowItem.date }}</td>
                        </tr>
                        <tr v-bind:key="rowIndex+'2'">
                          <td
                              v-for="colIndex in 10"
                              v-bind:key="rowIndex+colIndex"
                          >
                            <span v-if="!Object.keys(rowItem.classes).find(k=>rowItem.classes[k].num===colIndex)">&nbsp;</span>
                            <template v-else>
                              <template v-for="(dayItem, dayIndex) in rowItem.classes.filter(k=>k.num===colIndex)">
                                <div v-for="(classItem, classIndex) in dayItem.class.lessons" v-bind:key="rowIndex+':'+colIndex+':'+dayIndex+':'+classIndex" class="schedule-lesson-block">
                                  <h4>{{ classItem.lesson }} <br v-if="classItem.lessonType"> <span v-if="classItem.lessonType">[{{ classItem.lessonType }}]</span></h4>
                                  <template v-if="dayItem.class.city">
                                    <v-divider></v-divider>
                                    <p>{{ dayItem.class.city }}</p>
                                  </template>

                                  <v-divider></v-divider>

                                  <template v-if="dayItem.class.withSubGroups">
                                    <p>
                                      {{ classItem.subGroup.name }}
                                      <span class="schedule-text-small">({{ classItem.subGroup.num }}&nbsp;{{ $t("schedule.subgroup") }})</span>
                                    </p>
                                  </template>
                                  <template v-else>
                                    <p v-for="(groupItem, groupIndex) in dayItem.class.groups" :key="groupIndex">
                                      {{ groupItem.name }}
                                      <span v-if="groupItem.form" class="schedule-text-small">({{ groupItem.form }})</span>
                                    </p>
                                  </template>

                                  <v-divider></v-divider>

                                  <p v-if="classItem.room !== 'дом_ПК'">{{ $t("schedule.room") }} {{ classItem.room }}</p>
                                  <p v-else>{{ $t("schedule.remote") }}</p>

                                  <p v-if="classItem.room2 && classItem.distance === '0'">
                                    <v-divider></v-divider>
                                    {{ $t("schedule.shelter") }}: {{ $t("schedule.room") }} {{ classItem.room2 }}</p>

                                  <v-divider></v-divider>
                                  <p>{{ classItem.teacher }}</p>

                                </div>
                              </template>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                  <template v-else>
                    <div v-bind:key="'group'+groupIndex">
                      <h3 class="mb-5">{{ $t('schedule.no_results') }}</h3>
                      <p>{{ $t('schedule.try_again') }}</p>
                    </div>
                  </template>
            </template>

          <div v-html="$t('main_info')" v-if="data.clear && ! data.loading"></div>

      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .schedule-table {
    border-collapse: collapse;
  }

  .schedule-table .schedule-lesson-type, .schedule-table h4 > span {
    //display: inline-block;
    //padding: 4px 0;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 24px;
  }

  .schedule-table .schedule-text-small {
    font-size: 0.75rem;
  }

  .schedule-table .schedule-text-small-x {
    font-size: 0.6rem;
  }

  .schedule-table .schedule-date-row td {
    vertical-align: middle;
  }

  .schedule-lesson-block + .schedule-lesson-block {
    border-top: 3px solid rgba(153, 153, 153, 0.4);
    margin-top: 7px;
    padding-top: 7px;
  }

  .schedule-table td {
    width: 10%;
    line-height: 16px;
    vertical-align: top;
    padding: 10px !important;
  }
  .schedule-table th {
    padding: 0 2px 0 10px !important;
  }

  .schedule-table hr {
    margin: 4px 0;
  }

  .schedule-table p {
    margin: 3px 0;
  }

  .schedule-table td, .schedule-table th{
    border: 1px rgba(153, 153, 153, 0.3) solid;
    border-collapse: collapse;
  }

  .schedule-table tbody tr:hover {
    background-color: rgba(33, 150, 243, .1) !important;
  }
  .schedule-table tbody td:hover {
    background-color: rgba(33, 150, 243, .3)
  }

  #schedule_table > div {
    height: auto !important;
  }

  @media print {
    .schedule-lesson-block, .schedule-lesson-type {
      font-size: 10px !important;
    }
    #schedule-results {
      padding: 0 !important;
    }

    .v-data-table--fixed-header > .v-data-table__wrapper {
      height: auto !important;
    }

    .schedule-table .schedule-lesson-type {
      display: inline-block;
      padding: 4px 0;
      font-size: 0.5rem;
    }

    .schedule-table .schedule-text-small {
      font-size: 0.5rem;
    }

    .schedule-table .schedule-text-small-x {
      font-size: 0.5rem;
    }

    .schedule-table tr {
      break-inside: avoid;
    }

    .schedule-table tr.schedule-date-row {
      break-after: avoid;
    }

    .schedule-print-info p {
      margin-bottom: 0;
      margin-left: -10px;
    }

    .v-main {
      padding: 0;
    }
  }
</style>

<script>
import { useScheduleStore } from "@/stores/schedule";

export default {
  name: 'WelcomeInfo',

  setup() {
    const data = useScheduleStore();

    return {
      data
    }
  },

  data () {
    return {
      groupTab: 0,
      classItem: {},
      groupIndex: 0,
      isPrintMode: false,
    }
  },

  computed: {
    tableHeight() {
      if (this.isPrintMode) {
        return "auto"
      } else if (this.data.filterData.groups && this.data.table.length > 1) {
        return "calc(100vh - 136px)"
      } else {
        return "calc(100vh - 88px)";
      }
    }
  },

  methods: {
    handleBeforePrint() {
      this.isPrintMode = true;
    },

    handleAfterPrint() {
      this.isPrintMode = false;
    },

    addPrintEventListeners() {
      window.addEventListener('beforeprint', this.handleBeforePrint);
      window.addEventListener('afterprint', this.handleAfterPrint);
    },

    removePrintEventListeners() {
      window.removeEventListener('beforeprint', this.handleBeforePrint);
      window.removeEventListener('afterprint', this.handleAfterPrint);
    }
  },

  mounted() {
    this.addPrintEventListeners();
  },

  destroyed() {
    this.removePrintEventListeners();
  }
}
</script>
