<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text icon v-on="on">{{ currentLocal }}</v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, key) in langs"
          :key="key"
          @click="setLocale(key)"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useLocaleState } from "@/composables/locale";
import {useScheduleStore} from "@/stores/schedule";
export default {
  data() {
    return {
      langs: {
        en_US: "English",
        uk_UA: "Українська"
      }
    };
  },
  methods: {
    async setLocale(locale) {
      this.locale = locale;

      // Set the language of i18n and vuetify plugins
      this.$vuetify.lang.current = this.shortLocale
      this.$moment.locale(this.shortLocale)

      // Refresh schedule data
      await this.schedule.refreshSchedule()
    }
  },
  computed: {
    currentLocal() {
      return this.shortLocale.toUpperCase();
    }
  },
  setup() {
    const { shortLocale, locale } = useLocaleState();
    const schedule = useScheduleStore()
    return {
      locale,
      shortLocale,
      schedule
    };
  }
};
</script>

<style scoped>
  .theme--light.v-btn.v-btn--icon, .theme--dark.v-btn.v-btn--icon {
    color: white;
  }
</style>