<template>
  <v-checkbox
      v-model="$vuetify.theme.dark"
      color="black"
      off-icon="mdi-theme-light-dark"
      on-icon="mdi-theme-light-dark"
      class="d-flex dark-mode-button ml-3"
      @click="setDarkMode()"
  ></v-checkbox>
</template>

<script>

export default {
  name: "DarkModeButton",

  methods: {
    setDarkMode() {
      localStorage.setItem('darkMode', this.$vuetify.theme.dark.toString())
    }
  }
}
</script>

<style>
  .dark-mode-button .v-input__slot {
    align-items: center;
    justify-content: center;
   margin-top: 20px;
  }

  .dark-mode-button .black--text {
    color: white !important;
  }
</style>